import React, { Component } from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { RelatedPosts } from '../components/Posts/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image';
import { AddToQuote } from '../components/Quote/AddToQuote'
import { Breadcrumbs } from '../components/Breadcrumbs';
import './post.scss';
import './product.scss';
import { decodeEntities } from '../utils/helpers';
import RelatedProducts from '../components/Products/RelatedProducts';
import { PriceTable } from '../components/Acf/PriceTable';

class ProductPostTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: null
    }
  }

  setActiveImage = (e, image) => {
    this.setState({ activeImage: image })
  }
  render() {
    const { post, categories, location } = this.props
    const { activeImage } = this.state
    const { title, wordpress_id, content, featured_media, product_categories, acf } = post

    let tidyTitle = title.replace(' LR', ' <sup title="Long run">(LR)</sup>').replace(' SR', ' <sup title="Sort run">(SR)</sup>')
    const {
      product_dimensions,
      product_features,
      product_images,
      product_print_area,
      product_print_method,
      weight,
      minQty,
      maxQty,
      displayTitle,
      templates,
    } = acf

    const thisProductCategory = product_categories && categories && categories?.filter(cat => product_categories.includes(cat.node.wordpress_id))[0]

    const thisProductCategorySlug = thisProductCategory && thisProductCategory.node.slug

    const thisParentCategory = thisProductCategory && categories && categories.filter(pcat => pcat.node.wordpress_id === thisProductCategory.node.wordpress_parent)[0]

    const thisParentCategorySlug = thisParentCategory && thisParentCategory.node.slug

    const parentSlug = thisParentCategorySlug ? thisParentCategorySlug : thisProductCategorySlug


    let productId = wordpress_id
    let quoteType = parentSlug
    let parentPath = thisParentCategory?.node?.path
    let parentName = thisParentCategory?.node?.name

    switch (parentSlug) {
      case 'printing':
        quoteType = 'production'
        productId = null
        break;
      case 'packaging':
        quoteType = 'production'
        productId = null
        parentPath = '/production/bulk-storage-and-packaging/'
        break;
      case 'packaging-usb':
        quoteType = 'usb'
        productId = null
        parentPath = '/promotional-usb-flash-drives/'
        parentName = 'USB Flash Drives'
        break;
      case 'usb-extras':
        quoteType = 'usb'
        productId = null
        parentPath = '/promotional-usb-flash-drives/'
        parentName = 'USB Flash Drives'
        break;
      case 'usb':
        parentPath = '/promotional-usb-flash-drives/'
        parentName = 'USB Flash Drives'
        break;
      default:
    }

    const breadcrumbOverwrite = [
      { url: parentPath, title: parentName },
      { url: thisProductCategory?.node?.path, title: thisProductCategory?.node?.name },
      { url: null, title: title }
    ]

    return (
      <section className="single-product">
        <div className="wrap">
          <div className="inner">
            <div className="left">
              {featured_media && <div className="image-wrap"><Image src={activeImage ? activeImage : featured_media} position="relative" /></div>}
              {product_images && <div className="image-gallery">
                {product_images.map((image, index) =>
                  <div className="image" key={index} onClick={e => this.setActiveImage(e, image)}><div className="inner"><Image src={image} key={index} /></div></div>
                )}
              </div>
              }
            </div>
            <div className="right">
              <Breadcrumbs location={location} overwrite={breadcrumbOverwrite} />
              <h1 dangerouslySetInnerHTML={{ __html: displayTitle ? displayTitle : tidyTitle }} />
              <RenderShortcode content={content} />
              <AddToQuote quoteType={quoteType} productId={productId} />
              <h3>SPECIFICATIONS</h3>
              <div className="specifications">
                {product_dimensions && <div><h4>Dimensions:</h4><p>{product_dimensions}</p></div>}
                {product_features && <div><h4>Features:</h4><div className="features" dangerouslySetInnerHTML={{ __html: product_features }} /></div>}

                {product_print_area && <div><h4>Print Area:</h4><p>{product_print_area}</p></div>}
                {product_print_method && <div><h4>Print Method:</h4><ul>{product_print_method.map((item, index) => <li key={index}>{item}</li>)}</ul></div>}

                {weight && <div><h4>Weight:</h4><p>{weight}g</p></div>}
                {minQty && <div><h4>Min Qty:</h4><p>{minQty}</p></div>}
                {maxQty && <div><h4>Max Qty:</h4><p>{maxQty}</p></div>}

                {templates.length > 0 &&
                  <div>
                    <h4>Templates:</h4>
                    {templates && templates.map((template, index) =>
                      <p key={index}><a className="pdf" href={template?.product_template_file?.source_url} target="_blank">{template.product_template_name}</a></p>
                    )}
                  </div>
                }
              </div>

              {thisProductCategory && thisProductCategory.length ? (
                <div className="taxonomy">
                  <h4>Categories</h4>
                  <ul className="taglist">
                    {thisProductCategory.map(({ node: category }, index) => (
                      <li key={`${category.slug}cat`} key={index}>
                        {category.slug &&
                          <GatsbyLink to={category.path}>
                            {category.name}
                          </GatsbyLink>
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>

          <div className="blocks"></div>

        </div>
      </section>
    )
  }
}

const ProductPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings, categories } = data;
  if (!post) return null;
  const { title, yoast, product_categories, acf } = post;
  const {
    column
  } = acf
  const relatedProductCategory = product_categories[0]
  const exclude = [post.wordpress_id]
  const seoTitle = post?.acf?.displayTitle ? post?.acf?.displayTitle : title
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(seoTitle)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
      />
      <ProductPostTemplate
        location={location}
        post={post}
        blogSlug="products"
        categories={categories.edges}
      />
      {column && column?.length > 0 && <PriceTable {...acf} />}
      <RelatedProducts title="CHECK OUT SOME SIMILAR PRODUCTS" contentType="products" category={relatedProductCategory} exclude={exclude} />
    </Layout>
  )
}

export default Previewable(ProductPost, 'products');

export const pageQuery = graphql`
  fragment ProductFields on wordpress__wp_products {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProductPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressWpProducts(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ... GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      product_categories
      acf {
        product_dimensions
        product_features
        product_images
        product_print_area
        product_print_method
        templates {
          product_template_name
          product_template_file {
            source_url
          }
        }
        weight
        relatedCategory
        relatedProduct
        minQty
        maxQty
        displayTitle
        card_min_price
        card_min_qty
        intro
        outro
        column_count
        style
        qty_label
        price_label
        column {
          title
          row {
            qty
            price
          }
        }
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    categories: allWordpressWpProductCategories(sort: {order: ASC, fields: ord}) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
          description
          acf {
            displayTitle
            menuTitle
            featured_image {
              localFile {
                publicURL
              }
            }
            minQty
            maxQty
          }
        }
      }
    }
  }
`
