import React from 'react'
import './PriceTable.scss'

export const PriceTable = props => {
  const {
    intro,
    outro,
    column_count,
    price_label,
    qty_label,
    style,
    column,
  } = props

  let hasLabelLabel = false
  let hasValueLabel = false

  return (
    <section className="price-table light">
      <div className="inner">
        {intro && (
          <div className="intro" dangerouslySetInnerHTML={{ __html: intro }} />
        )}
        {column && (
          <div className={`columns col-${column_count}`}>
            {column.map((col, i) => {
              const { title, row } = col
              row.map(rowV => {
                if (rowV.qty) hasLabelLabel = true
                if (rowV.price) hasValueLabel = true
              })
              return (
                <div className={`column ${style}`} key={i}>
                  <h4>{title}</h4>
                  <table className={hasLabelLabel ? '' : 'center'}>
                    {style == 'horizontal' && (
                      <thead>
                        <tr>
                          {hasLabelLabel && <th>{qty_label}</th>}
                          {hasValueLabel && <th>{price_label}</th>}
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {row.map((rowV, i) => {
                        return (
                          <tr key={i}>
                            {hasLabelLabel && (
                              <td className="label">{rowV.qty}</td>
                            )}
                            {hasValueLabel && (
                              <td className="value">{rowV.price}</td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>
        )}
        {outro && (
          <div className="outro" dangerouslySetInnerHTML={{ __html: outro }} />
        )}
      </div>
    </section>
  )
}
